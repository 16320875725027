/* eslint-disable no-console */

import React, { useEffect, useState, useContext } from 'react';
import { AnalyticsContext, trackEvent, PageTracking } from 'latitude-analytics';
import {
  Button,
  Container,
  DatePicker,
  Flex,
  Form,
  Grid,
  Input,
  LoadingIcon,
  RadioGroup,
  Select,
  Text
} from 'basis';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import styled from '@emotion/styled';
import { Link as ButtonLink } from '@latitude/link';
import { Box } from '@latitude/box';
import { ProgressStepper } from '@/components/ProgressStepper/ProgressStepper';
import { PersonalLoanSoftQuoteHeader } from '@/components/PersonalLoanSoftQuote/PersonalLoanSoftQuoteHeader';
import { titleOptions } from '@/data/pages/soft-quote/titleList';
import { PAGES } from '@/components/PersonalLoanSoftQuote/pages-au/PersonalLoanSoftQuoteReducer';
import { FormLayout } from '@/components/PersonalLoanSoftQuote/FormLayout';
import { HasLicenceOptions } from '../constants/driversLicence';
import { DriversLicenceInput } from '../driversLicence/DriversLicenceInput';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import {
  ANALYTICS_SITESUBSECTION,
  PADDING,
  SOFT_QUOTE_AU_RETRIEVE_URL,
} from '@/utils/constants';
import { validateLoanAmount, getDefaultLoanAmount } from '@/components/EstimateRateWidget/MainRateWidget';
import ReferredMessage from '@/components/PersonalLoanSoftQuote/ReferredMessage';
import { NonFormLayout } from '@/components/PersonalLoanSoftQuote/NonFormLayout';
import {
  auDriverLicenceValidation,
  dateOfBirthValidation,
  emailValidation,
  firstNameValidation,
  genderValidation,
  lastNameValidation,
  middleNameValidation,
  mobileValidation,
  titleValidation
} from '@/components/PersonalLoanSoftQuote/validator/aboutYouValidator';
import { ServerError } from '@/components/PersonalLoanSoftQuote/ServerError';
import { Eligibility } from '@/components/PersonalLoanSoftQuote/Eligibility';
import { CHANNEL, LOAN_PURPOSE_AU } from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {getEnvForDebug, getUrlFromEnv} from '@/utils/getUrlFromEnvUtil';
import {dataLayerOnAboutSubmit, dataLayerOnFirstPageLoad, dataLayerOnAboutState, dataLayerOnAboutYouReferred} from '@/utils/dataLayerUtil';
import {checkForDuplicateQuote} from '@/components/PersonalLoanSoftQuote/library/softQuoteProxyApi';
import tealiumConfig from '../../../../tealium';

const RadioButtonWrapper = styled.div`
  div[role='radio'] label {
    margin-bottom: 0 !important;
  }
`;


const PinkInfoBanner = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid #f682bc;
  background-color: #ffeff7;
  padding: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    width: calc(50% - 8px);
    @media (max-width: ${BREAKPOINT.SM}) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const WidgetContainer = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    /* allows widget to overflow for correct spacing */
    height: 200px;
  }
`;
const DEBUG = getEnvForDebug();

export function AboutYou({ state, dispatch, isBetterStart, defaults }) {
  const [analytics] = useContext(AnalyticsContext);

  dataLayerOnFirstPageLoad(analytics);

  const DUPLICATE_QUOTE_NOT_EXISTS_DESCRIPTION = 'Quote_does_not_exists';
  const DUPLICATE_QUOTE_EXISTS_DESCRIPTION = 'Quote_Already_exists';
  const QUOTE_STATUS_REFERRED = 'Referred';
  const QUOTE_STATUS_DECLINED = 'Declined';

  const [showLoader, setShowLoader] = useState(false);
  const [showReferred, setShowReferred] = useState(false);
  const [widgetValues, setWidgetValues] = useState({
      loanAmount: defaults.loanAmount ? defaults.loanAmount : getDefaultLoanAmount({ isBetterStart }) ,
      purpose: defaults.purpose ? defaults.purpose : isBetterStart ? LOAN_PURPOSE_AU.DEBT_CONSOLIDATION : LOAN_PURPOSE_AU.NOT_SELECTED,
      frequency: defaults.frequency,
      repaymentPeriod: defaults.repaymentPeriod ? defaults.repaymentPeriod : `5${isBetterStart ? '.25' : ''}`,
      purposeOther: (defaults.purpose===LOAN_PURPOSE_AU.OTHER && defaults.purposeOther)? defaults.purposeOther : '',
  });
  const [showServerError, setShowServerError] = useState(false);
  const [loadWidget, setLoadWidget] = useState(false);

  let showPurposeError = false;
  let showPurposeOtherError = false;
  let showAmountMinError = false;
  let showAmountMaxError = false;
  let loanAmountIsValid = false;

  const [referredScreenName, setReferredScreenName] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (defaults.partnername) {
        sessionStorage.setItem('partnername', defaults.partnername);
      }
      // TODO: if queryparams are empty get the session values otherwise perform below
      if (defaults.loanAmount || (!defaults.loanAmount && state.formValues.firstName === '')) {
        sessionStorage.setItem('loanAmount', widgetValues.loanAmount);
        sessionStorage.setItem('purpose', widgetValues.purpose);
        sessionStorage.setItem('repaymentPeriod', widgetValues.repaymentPeriod);
        if (widgetValues.purpose === LOAN_PURPOSE_AU.OTHER && widgetValues.purposeOther) {
          sessionStorage.setItem('purposeOther', widgetValues.purposeOther);
        }
      }
      setLoadWidget(true);
    }
  }, []);

  const onSubmit = ({ values, errors }) => {
    if (!values.hasDriverLicence === HasLicenceOptions.no) {
      delete errors.licenceNumber;
    }
    if (widgetValues.purpose === LOAN_PURPOSE_AU.NOT_SELECTED) {
      showPurposeError = true;
    }

    if (widgetValues.purpose === LOAN_PURPOSE_AU.OTHER && widgetValues.purposeOther === '') {
      showPurposeOtherError = true;
    }
    // Validation of the loanAmount in the widget became more complicated when Better Start was implmeneted, so was moved to a common subroutine exported from MainRateWidget
    const loanAmountValidationResult = validateLoanAmount({ amountValue: widgetValues.loanAmount, isBetterStart });
    loanAmountIsValid = !(loanAmountValidationResult.isError || loanAmountValidationResult.isWarning);
    showAmountMinError = loanAmountValidationResult.showAmountMinError;
    showAmountMaxError = loanAmountValidationResult.showAmountMaxError;
    if (
      !showPurposeError &&
      !showPurposeOtherError &&
      loanAmountIsValid && 
      Object.keys(errors).length === 0
    ) {
      dataLayerOnAboutSubmit(analytics, widgetValues, values );
      if(DEBUG) console.log(analytics);
      trackEvent(analytics, {
        category: 'quote',
        action: 'quote-navigation',
        location: 'About',
        label: 'Next'
      });
      const duplicateCheckData = {
        emailAddress: values.email,
        mobileNumber: values.mobileNumber,
        dateOfBirth: `${values.dateOfBirth.day.padStart(2, '0')}/${values.dateOfBirth.month.padStart(2, '0')}/${values.dateOfBirth.year}`
      };
      setShowLoader(true);
      window.scroll(0, 0);

      checkForDuplicateQuote(
        duplicateCheckData
      )
        .then(response => {
          let responseDescription = response.data.responseDescription;
          let quoteStatus = response.data.quoteStatus;

          if (DUPLICATE_QUOTE_NOT_EXISTS_DESCRIPTION === responseDescription) {
            dispatch({ type: PAGES.EMPLOYMENT, formValues: values });
            setShowLoader(false);
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            QUOTE_STATUS_REFERRED === quoteStatus
          ) {
            setReferredScreenName(values.firstName);
            setShowLoader(false);
            dataLayerOnAboutYouReferred(analytics)
            setShowReferred(true);
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            QUOTE_STATUS_DECLINED === quoteStatus
          ) {
            setShowLoader(false);
            dispatch({ type: PAGES.EMPLOYMENT, formValues: values });
          } else if (
            DUPLICATE_QUOTE_EXISTS_DESCRIPTION === responseDescription &&
            (quoteStatus !== QUOTE_STATUS_REFERRED && quoteStatus !== QUOTE_STATUS_DECLINED)
          ) {
            window.location.href = `${SOFT_QUOTE_AU_RETRIEVE_URL}?duplicate-check`;
          }
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          if(DEBUG) console.log('error on duplicate quote', error);
          setShowLoader(false);
          setShowServerError(true);
        });
    } else {
      if (DEBUG) {
        console.log('Errors found in PersonalLoadSoftQuoteAboutYou');
        console.log({
          showPurposeError,
          showPurposeOtherError,
          loanAmountIsValid,
          errors,
        });
      }
      let widgetErrors = document.querySelectorAll(
        '[class*=Error], [class*=error]'
      );

      let purposeSelect = document.querySelectorAll('select#purpose');
      let purposeOtherSelect = document.querySelectorAll('input#other-purpose');
      let ariaInvalid = document.querySelectorAll('[aria-invalid=true]');
      let loanAmountInput = document.querySelectorAll(
        'input.currency-input__field'
      );

      if (showPurposeError) {
        purposeSelect.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (showPurposeOtherError) {
        purposeOtherSelect.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (showAmountMinError || showAmountMaxError) {
        loanAmountInput.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (widgetErrors.length > 0) {
        widgetErrors.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      } else if (ariaInvalid.length > 0) {
        ariaInvalid.item(0).scrollIntoView({
          block: 'center',
          inline: 'nearest',
          behavior: 'smooth'
        });
      }
    }
  };

  return showLoader ? (
    <NonFormLayout>
      <Box padding={PADDING.P16} width="100%" height="80vh">
        <Flex height="100%" placeItems="center">
          <LoadingIcon size="medium" />
        </Flex>
      </Box>
    </NonFormLayout>
  ) : showReferred ? (
    <PageTracking
      path={`/${ANALYTICS_SITESUBSECTION}/about-you-referred`}
      exceptions={tealiumConfig.pageDataExceptions}
    >
      <NonFormLayout>
        <ReferredMessage name={referredScreenName} />
      </NonFormLayout>
    </PageTracking>
  ) : showServerError ? (
    <PageTracking
      path={`/${ANALYTICS_SITESUBSECTION}/about-you-server-error`}
      exceptions={tealiumConfig.pageDataExceptions}
    >
      <NonFormLayout>
        <ServerError hideTryAgain />
      </NonFormLayout>
    </PageTracking>
  ) : (
      <FormLayout>
        <Form initialValues={state.formValues} onSubmit={onSubmit}>
          {({ state }) => {
            return (
              dataLayerOnAboutState(analytics, state) &&
              <Grid preset="page" rowsGap={4}>
                <Grid.Item colSpan="all">
                  <PersonalLoanSoftQuoteHeader />
                </Grid.Item>
                <Grid.Item
                  colSpan="all"
                  colSpan-md="1-6"
                  colSpan-lg="1-10"
                  rowSpan="1"
                  rowSpan-lg="1"
                >
                  <Container
                    bg="grey.t07"
                    padding="0"
                    margin="0 4"
                    margin-md="0"
                  >
                    <ProgressStepper currentLabel="About" />
                  </Container>
                </Grid.Item>
                <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="1-6">
                  <Container
                    bg="white"
                    padding="0"
                    margin="0 4"
                    margin-sm="0 4"
                    margin-md="0"
                  >
                    <Eligibility channel={CHANNEL.AU} isBetterStart={isBetterStart} />
                  </Container>
                </Grid.Item>

                <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="7-10">
                  <Container margin="0 4 0" margin-md="0">
                    <WidgetContainer>
                      {loadWidget && (
                        <EstimateRateWidget
                          quote
                          showPurposeError={showPurposeError}
                          setWidgetValues={setWidgetValues}
                          showPurposeOtherError={showPurposeOtherError}
                          showAmountMinError={showAmountMinError}
                          showAmountMaxError={showAmountMaxError}
                          isBetterStart={isBetterStart}
                        />
                      )}
                    </WidgetContainer>
                  </Container>
                </Grid.Item>

                <Grid.Item
                  colSpan="all"
                  colSpan-md="1-6"
                  colSpan-lg="1-6"
                  rowSpan="4"
                  rowSpan-lg="3"
                >
                  <Container
                    bg="white"
                    padding="0"
                    margin="0 4"
                    margin-sm="0 4"
                    margin-md="0"
                  >
                    <Container bg="grey.t07" padding="4" margin="0">
                      <Grid rowsGap={6}>
                        {/* Siblings are customers who already have a Personal Loan with Latitude */}
                        <RadioGroup
                          name="sibling"
                          label="Do you have a Latitude Personal Loan?"
                          options={[
                            {
                              label: 'No',
                              value: 'no'
                            },
                            {
                              label: 'Yes',
                              value: 'yes'
                            }
                          ]}
                        />
                        {state.values.sibling === 'yes' && (
                          <PageTracking
                            path={`/${ANALYTICS_SITESUBSECTION}/about-you-sibling`}
                            exceptions={tealiumConfig.pageDataExceptions}
                          >
                          <>
                            <Text>
                              {isBetterStart?
                                `Better Start Personal Loans are only available to new Personal Loan customers,
                                however you can apply for a debt consolidation loan by clicking
                                'Apply Now' below or call us on the number below to discuss your needs.`
                              :
                                `Our Personal Loans are tailored to you, which
                                means that we take into consideration the current
                                loan you have with us, before we can provide you
                                with an interest rate. You can either proceed to
                                'Apply Now' below or call us on the
                                number below to discuss your needs.`
                              }
                            </Text>
                            <ButtonContainer>
                              <ButtonLink
                                button="tertiary"
                                href="tel:1300973422"
                                trackEventData={{
                                  location: 'About-Existing Loan'
                                }}
                              >
                                Call 1300 973 422
                              </ButtonLink>
                              <ButtonLink
                                button="primary"
                                target="_self"
                                href={getUrlFromEnv('pl-apply-url-au')}
                                trackEventData={{
                                  category: 'cta',
                                  action: 'discovery-link',
                                  location: 'About-Existing Loan'
                                }}
                                trackId="btn-existing-loan"
                                disableDefaultFunnelTracking
                              >
                                Apply Now
                              </ButtonLink>
                            </ButtonContainer>
                          </>
                          </PageTracking>
                        )}
                        {state.values.sibling === 'no' && (
                          <>
                            <RadioGroup
                              name="numberOfPeopleRequesting"
                              label="How many people are requesting a quote?"
                              options={[
                                {
                                  label: '1 person',
                                  value: '1 person'
                                },
                                {
                                  label: '2 people',
                                  value: '2 people'
                                }
                              ]}
                            />
                            {state.values.numberOfPeopleRequesting ===
                              '1 person' && (
                              <>
                                <PinkInfoBanner>
                                  <Text>
                                    <strong>Important:</strong> Please enter
                                    your full legal name and date of birth as
                                    shown on your driver&apos;s licence or
                                    passport as these details will help us
                                    verify you.
                                  </Text>
                                </PinkInfoBanner>
                                <Select
                                  name="title"
                                  label="Title"
                                  options={titleOptions}
                                  testId="title"
                                  validate={titleValidation}
                                />
                                <Input
                                  name="firstName"
                                  label="First name"
                                  testId="first-name"
                                  placeholder="Legal name as shown on your ID"
                                  validate={firstNameValidation}
                                />
                                <Input
                                  name="middleName"
                                  label="Middle name"
                                  testId="middle-name"
                                  placeholder="If you have one"
                                  validate={middleNameValidation}
                                />
                                <Input
                                  name="lastName"
                                  label="Last name"
                                  testId="last-name"
                                  placeholder="Legal name as shown on your ID"
                                  validate={lastNameValidation}
                                />
                                <RadioButtonWrapper>
                                  <RadioGroup
                                    name="gender"
                                    label="Gender"
                                    options={[
                                      {
                                        label: 'Male',
                                        value: 'Male'
                                      },
                                      {
                                        label: 'Female',
                                        value: 'Female'
                                      }
                                    ]}
                                    validate={genderValidation}
                                  />
                                </RadioButtonWrapper>
                                <DatePicker
                                  name="dateOfBirth"
                                  label="Date of birth"
                                  testId="date-of-birth"
                                  validate={dateOfBirthValidation}
                                />
                                <PinkInfoBanner>
                                  <Text>
                                    <strong>Important:</strong> Please enter
                                    your correct email and mobile number as this
                                    will be used to start your application, or
                                    to simply review your quote again.
                                  </Text>
                                </PinkInfoBanner>
                                <Input
                                  name="email"
                                  type="email"
                                  label="Email address"
                                  testId="email-address"
                                  helpText="This is the email we’ll send your rate quote to"
                                  validate={emailValidation}
                                />
                                <Input
                                  name="mobileNumber"
                                  variant="numeric"
                                  label="Mobile number"
                                  testId="mobile-number"
                                  validate={mobileValidation}
                                />
                                {/** lm-3985 migrated au and broker (but not GEM) to using a new hasDriversLicence ternary value - we extract this into a sub component to allow re-use in AU and Broker */}
                                <DriversLicenceInput
                                  formValues = {state.values}
                                  channel = {CHANNEL.AU}
                                  driverLicenceValidation = {auDriverLicenceValidation}
                                /> 
                                <Button
                                  type="submit"
                                  color="green"
                                  testId="btn-next"
                                >
                                  Next
                                </Button>
                              </>
                            )}

                            {state.values.numberOfPeopleRequesting ===
                              '2 people' && (
                              <PageTracking
                                path={`/${ANALYTICS_SITESUBSECTION}/about-you-joint`}
                                exceptions={tealiumConfig.pageDataExceptions}
                              >
                              <>
                                <Text>
                                  At this stage, we can only provide single quotes. You can either start a
                                  {isBetterStart?' joint Better Start Personal Loan application ':' Joint Personal Loan Application '}
                                  or call us if you have any questions.
                                </Text>
                                <ButtonContainer>
                                  <ButtonLink
                                    button="tertiary"
                                    href="tel:1300973422"
                                    trackEventData={{
                                      location: 'About-Joint application'
                                    }}
                                  >
                                    Call 1300 973 422
                                  </ButtonLink>
                                  <ButtonLink
                                    button="primary"
                                    href={`${getUrlFromEnv('pl-apply-url-au')}${isBetterStart?'-better-start':''}`}
                                    trackEventData={{
                                      category: 'cta',
                                      action: 'discovery-link',
                                      location: 'About-Joint application'
                                    }}
                                    target="_self"
                                    trackId="btn-joint-application"
                                    disableDefaultFunnelTracking
                                  >
                                    Joint application
                                  </ButtonLink>
                                </ButtonContainer>
                              </>
                              </PageTracking>
                            )}
                          </>
                        )}
                      </Grid>
                    </Container>
                  </Container>
                </Grid.Item>
              </Grid>
            );
          }}
        </Form>
      </FormLayout>
  );
}
